
import { defineComponent, ref } from 'vue'
import firebase from 'firebase/app'

export default defineComponent({
	name: 'LoginFacebookForTest',
	setup() {
		const facebookToken = ref<string|undefined>()
		const loginFacebook = () => {
			//
			const provider = new firebase.auth.FacebookAuthProvider()
			firebase
				.auth()
				.signInWithPopup(provider)
				.then((result) => {
				/** @type {firebase.auth.OAuthCredential} */
					const credential = (result.credential) as firebase.auth.OAuthCredential
					const firebaseUserData = result.user

					facebookToken.value = credential.accessToken
                    
				})
				.catch((error) => {
					console.error('facebook signin error :', error)
				})
		}

		return {
			facebookToken,
			loginFacebook
		}
	},
})
